<template>
  <div class="container_box details_box_styles">
    <!--拣货单设置-->
    <div class="basic_information_box">
      <Card :bordered="false" dis-hover>
        <template #title>
          <h2 class="title_box">{{ $t('key1003689') }}</h2>
        </template>
        <Form :model="pickingParams" :label-width="250" label-position="right" @submit.native.prevent>
          <Row type="flex" :gutter="gutterItem">
            <Col :span="twoItemCol">
              <FormItem :label="$t('key1003685')">
                <Select v-model="pickingParams.singlePrintOrder" style="width: 300px" filterable>
                  <Option v-for="item in packSortList" :key="item.value" :value="item.value">{{ item.label }}</Option>
                </Select>
              </FormItem>
            </Col>
            <Col :span="twoItemCol">
              <FormItem :label="$t('key1003686')">
                <Select v-model="pickingParams.multiPrintOrder" style="width: 300px" filterable>
                  <Option v-for="item in packSortList" :key="item.value" :value="item.value">{{ item.label }}</Option>
                </Select>
              </FormItem>
            </Col>
          </Row>
        </Form>
      </Card>
    </div>
    <!--包装作业设置-->
    <div class="basic_information_box">
      <Card :bordered="false" dis-hover>
        <template #title><h2 class="title_box">{{ $t('key1003691') }}</h2></template>
        <Form :model="pageParams" :label-width="300" label-position="right" @submit.native.prevent>
          <Row type="flex" :gutter="gutterItem">
            <!-- 自动分拣 -->
            <Col :span="twoItemCol">
              <Form-item :label="$t('key1005799')">
                <div style="display: flex; align-items: center;">
                  <RadioGroup class="radio_group_styles" v-model="pageParams.autoPack">
                    <Radio label="Y">{{ $t('key1005804') }}</Radio>
                    <Radio label="N">{{ $t('key1000204') }}</Radio>
                  </RadioGroup>
                  <Checkbox v-model="pageParams.print" style="margin-left:10px;">{{ $t('key1005805') }}</Checkbox>
                </div>
              </Form-item>
            </Col>
            <!--拣货复核时进行包装-->
            <Col :span="twoItemCol">
              <Form-item :label="$t('key1005800')">
                <RadioGroup class="radio_group_styles" v-model="pageParams.reviewPackaging">
                  <Radio label="Y">{{ $t('key1000467') }}</Radio>
                  <Radio label="N">{{ $t('key1000466') }}</Radio>
                </RadioGroup>
                <Tooltip max-width="250" :content="$t('key1005801')" class="tooltip_boxs">
                  <Icon class="help_icon" size="20" color="#2D8CF0" type="ios-alert"></Icon>
                </Tooltip>
              </Form-item>
            </Col>
            <!-- 称重 -->
            <Col :span="twoItemCol">
              <Form-item :label="$t('key1005802')">
                <RadioGroup class="radio_group_styles" v-model="pageParams.weighingPack">
                  <Radio label="Y">{{ $t('key1003109') }}</Radio>
                  <Radio label="N">{{ $t('key1005806') }}</Radio>
                </RadioGroup>
              </Form-item>
            </Col>
            <!-- 标记为已发货 -->
            <Col :span="twoItemCol" v-if="isShowsTampPackage">
              <Form-item :label="$t('key1005803')">
                <RadioGroup class="radio_group_styles" v-model="pageParams.stampPackage">
                  <Radio label="Y">{{ $t('key1000467') }}</Radio>
                  <Radio label="N">{{ $t('key1000466') }}</Radio>
                </RadioGroup>
              </Form-item>
            </Col>
            <!-- 云卖中心仓备货时进行包装作业 -->
            <Col :span="twoItemCol">
              <Form-item :label="`${configCharacters}` + $t('key1005798')">
                <RadioGroup class="radio_group_styles" v-model="pageParams.ymsPackagingOperation">
                  <Radio label="Y">{{ $t('key1005804') }}</Radio>
                  <Radio label="N">{{ $t('key1000204') }}</Radio>
                </RadioGroup>
              </Form-item>
            </Col>
          </Row>
        </Form>
      </Card>
    </div>
    <!--分拣架设置-->
    <div class="basic_information_box">
      <Card :bordered="false" dis-hover>
        <template #title><h2 class="title_box">{{ $t('key1005807') }}</h2></template>
        <div style="background:#fff;padding:10px 10px;">
          <Row>
            <Col span="8">
              <div style="marginTop:10px;marginLeft:40%;">
                <Checkbox v-model="pickBoxSetting.leftBoxStatus">{{ $t('key1003693') }}</Checkbox>
              </div>
              <div style="marginLeft:30%;marginTop:20px;">
                {{ $t('key1003694') }}
                <Select v-model="pickBoxSetting.leftBoxRow" style="width:50px;margin:0 5px;">
                  <Option value="1">1</Option>
                  <Option value="2">2</Option>
                  <Option value="3">3</Option>
                  <Option value="4">4</Option>
                </Select>
                {{ $t('key1003695') }}
                <Select v-model="pickBoxSetting.leftBoxCol" style="width:50px;margin:0 5px;">
                  <Option value="1">1</Option>
                  <Option value="2">2</Option>
                  <Option value="3">3</Option>
                  <Option value="4">4</Option>
                  <Option value="5">5</Option>
                </Select>
                {{ $t('key1003696') }}
              </div>
              <div style="marginTop:20px;">
                <Row class="pick_box_setting" v-for="(item, index) in Number(pickBoxSetting.leftBoxRow)" :key="item">
                  <Col v-for="(i, j) in Number(pickBoxSetting.leftBoxCol)" :key="j">
                    <div class="pickBox">
                      <div style="border-top: 15px solid #ed4014;border-right: 15px solid transparent;float:left;"></div>
                      <div style="text-align:center;margin-top:15px;">{{ 100 + 10 * (index + 1) + (j + 1) }}</div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col span="8" style="border-left:1px solid #d7d7d7;">
              <div style="marginTop:10px;marginLeft:40%;">
                <Checkbox v-model="pickBoxSetting.midBoxStatus">{{ $t('key1003697') }}</Checkbox>
              </div>
              <div style="marginLeft:30%;marginTop:20px;">
                {{ $t('key1003694') }}
                <Select v-model="pickBoxSetting.midBoxRow" style="width:50px;margin:0 5px;">
                  <Option value="1">1</Option>
                  <Option value="2">2</Option>
                  <Option value="3">3</Option>
                  <Option value="4">4</Option>
                </Select>
                {{ $t('key1003695') }}
                <Select v-model="pickBoxSetting.midBoxCol" style="width:50px;margin:0 5px;">
                  <Option value="1">1</Option>
                  <Option value="2">2</Option>
                  <Option value="3">3</Option>
                  <Option value="4">4</Option>
                  <Option value="5">5</Option>
                </Select>
                {{ $t('key1003696') }}
              </div>
              <div style="marginTop:20px;marginLeft:10px;">
                <Row class="pick_box_setting" v-for="(item, index) in Number(pickBoxSetting.midBoxRow)" :key="item">
                  <Col v-for="(i, j) in Number(pickBoxSetting.midBoxCol)" :key="j">
                    <div class="pickBox">
                      <div style="border-top: 15px solid #ff9900;border-right: 15px solid transparent;float:left;"></div>
                      <div style="text-align:center;marginTop:15px;">{{ 200 + 10 * (index + 1) + (j + 1) }}</div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col span="8" style="border-left:1px solid #d7d7d7;">
              <div style="marginTop:10px;marginLeft:40%;">
                <Checkbox v-model="pickBoxSetting.rigBoxStatus">{{ $t('key1003698') }}</Checkbox>
              </div>
              <div style="marginLeft:30%;marginTop:20px;">
                {{ $t('key1003694') }}
                <Select v-model="pickBoxSetting.rigBoxRow" style="width:50px;margin:0 5px;">
                  <Option value="1">1</Option>
                  <Option value="2">2</Option>
                  <Option value="3">3</Option>
                  <Option value="4">4</Option>
                </Select>
                {{ $t('key1003695') }}
                <Select v-model="pickBoxSetting.rigBoxCol" style="width:50px;margin:0 5px;">
                  <Option value="1">1</Option>
                  <Option value="2">2</Option>
                  <Option value="3">3</Option>
                  <Option value="4">4</Option>
                  <Option value="5">5</Option>
                </Select>
                {{ $t('key1003696') }}
              </div>
              <div style="marginTop:20px;marginLeft:10px;">
                <Row class="pick_box_setting" v-for="(item, index) in Number(pickBoxSetting.rigBoxRow)" :key="item">
                  <Col v-for="(i, j) in Number(pickBoxSetting.rigBoxCol)" :key="j">
                    <div class="pickBox">
                      <div style="border-top: 15px solid #2db7f5;border-right: 15px solid transparent;float:left;"></div>
                      <div style="text-align:center;marginTop:15px;">{{ 300 + 10 * (index + 1) + (j + 1) }}</div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </Card>
    </div>
    <!--底部操作按钮-->
    <div class="options_content">
      <Button
        type="primary"
        class="save_btn_style"
        v-if="getPermission('packageSettingCommon_updateSortingSetting')"
        @click="save">
        {{ $t('key1003700') }}
      </Button>
    </div>
  </div>
</template>

<script>
import { alias689d3582fa8c4a7891f9d6ea8bcd03a9 } from '@/customFolder/customVueAlias.js';

import Mixin from '@/components/mixin/common_mixin';
import api from '@/api/';

export default {
  name: 'sortOrderSetting',
  mixins: [Mixin],
  data() {
    return {
      twoItemCol: 12,
      packSortList: [
        {
          label: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005808'),
          value: '1'
        }, {
          label: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1003701'),
          value: '2'
        }
      ],
      pickingParams: {
        singlePrintOrder: '0',
        multiPrintOrder: '0'
      },
      pageParams: { // 包装作业设置
        autoPack: 'Y', // 自动分拣
        print: false, // 是否打印
        reviewPackaging: 'Y', // 拣货复核时包装
        weighingPack: 'Y', // 称重
        stampPackage: 'Y', // 标记已发货
        ymsPackagingOperation: 'Y', // 云卖中心仓备货时进行包装作业
      },
      pickBoxSetting: { // 分拣架设置
        leftBoxStatus: true, // 启用左侧分拣货架
        midBoxStatus: true, // 中间
        rigBoxStatus: true, // 右侧
        leftBoxRow: '4', // 左侧货架行
        leftBoxCol: '5', // 列
        midBoxRow: '4',
        midBoxCol: '5',
        rigBoxRow: '4',
        rigBoxCol: '5'
      }
    };
  },
  created() {
    this.getSetting();
  },
  methods: {
    save() { // 保存
      let v = this;
      if (!v.pickBoxSetting.leftBoxStatus && !v.pickBoxSetting.midBoxStatus && !v.pickBoxSetting.rigBoxStatus) {
        v.$Message.error(alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005809'));
        return false;
      }
      let pos = [];
      // 组装参数，N否Y是
      let leftShelfTypeVal = v.pickBoxSetting.leftBoxRow + 'x' + v.pickBoxSetting.leftBoxCol;
      let midShelfTypeVal = v.pickBoxSetting.midBoxRow + 'x' + v.pickBoxSetting.midBoxCol;
      let rightShelfTypeVal = v.pickBoxSetting.rigBoxRow + 'x' + v.pickBoxSetting.rigBoxCol;
      pos.push({
          paramKey: 'enableAutoSorting',
          paramValue: v.pageParams.autoPack
        },
        {
          paramKey: 'enableSortingPrinted',
          paramValue: v.pageParams.print ? 'Y' : 'N'
        },
        {
          paramKey: 'enablePackingWeight',
          paramValue: v.pageParams.weighingPack
        },
        {
          paramKey: 'enablePackingPrintedPackageShip',
          paramValue: v.pageParams.stampPackage
        },
        {
          paramKey: 'enablePackagingOperation',
          paramValue: v.pageParams.ymsPackagingOperation
        },
        {
          paramKey: 'enableLeftShelfType',
          paramValue: v.pickBoxSetting.leftBoxStatus ? 'Y' : 'N'
        },
        {
          paramKey: 'enableMidShelfType',
          paramValue: v.pickBoxSetting.midBoxStatus ? 'Y' : 'N'
        },
        {
          paramKey: 'enableRightShelfType',
          paramValue: v.pickBoxSetting.rigBoxStatus ? 'Y' : 'N'
        },
        {
          paramKey: 'singlePrintOrder',
          paramValue: v.pickingParams.singlePrintOrder
        },
        {
          paramKey: 'multiPrintOrder',
          paramValue: v.pickingParams.multiPrintOrder
        },
        {
          paramKey: 'leftShelfType',
          paramValue: leftShelfTypeVal
        },
        {
          paramKey: 'midShelfType',
          paramValue: midShelfTypeVal
        },
        {
          paramKey: 'rightShelfType',
          paramValue: rightShelfTypeVal
        },
        {
          paramKey: 'enablePickingReviewForPackaging',
          paramValue: v.pageParams.reviewPackaging
        });
      let obj = {
        paramBoList: pos
      };
      v.axios.put(api.update_sortingSetting, obj, {loading: true}).then(response => {
        if (response.data.code === 0) {
          v.$Message.success(alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000102'));
          v.getSetting();
        }
      });
    },
    // 获取设置信息
    getSetting() {
      let v = this;
      let obj = {
        typeList: ['packing', 'sorting', 'pickingGoods'] // packing表示查询包装作业设置，sorting表示查询分拣架设置 pickingGoods拣货单
      };
      v.axios.post(api.get_packageSetting, obj).then(response => {
        if (response.data.code === 0) {
          let data = response.data.datas;
          if (data) {
            data.forEach((n, i) => {
              if (n.paramKey === 'leftShelfType') { // 获取拣货架
                let value = n.paramValue.split('x');
                v.pickBoxSetting.leftBoxRow = value[0];
                v.pickBoxSetting.leftBoxCol = value[1];
              } else if (n.paramKey === 'midShelfType') {
                let value = n.paramValue.split('x');
                v.pickBoxSetting.midBoxRow = value[0];
                v.pickBoxSetting.midBoxCol = value[1];
              } else if (n.paramKey === 'rightShelfType') {
                let value = n.paramValue.split('x');
                v.pickBoxSetting.rigBoxRow = value[0];
                v.pickBoxSetting.rigBoxCol = value[1];
              } else if (n.paramKey === 'enableLeftShelfType') { // 获取拣货架状态
                v.pickBoxSetting.leftBoxStatus = n.paramValue === 'Y';
              } else if (n.paramKey === 'enableMidShelfType') {
                v.pickBoxSetting.midBoxStatus = n.paramValue === 'Y';
              } else if (n.paramKey === 'enableRightShelfType') {
                v.pickBoxSetting.rigBoxStatus = n.paramValue === 'Y';
              } else if (n.paramKey === 'enableAutoSorting') { // 获取包装设置
                v.pageParams.autoPack = n.paramValue;
              } else if (n.paramKey === 'enableSortingPrinted') {
                v.pageParams.print = n.paramValue === 'Y';
              } else if (n.paramKey === 'enablePackingWeight') {
                v.pageParams.weighingPack = n.paramValue;
              } else if (n.paramKey === 'enablePickingReviewForPackaging') {
                v.pageParams.reviewPackaging = n.paramValue;
              } else if (n.paramKey === 'enablePackingPrintedPackageShip') {
                v.pageParams.stampPackage = n.paramValue;
              } else if (n.paramKey === 'enablePackagingOperation') {
                v.pageParams.ymsPackagingOperation = n.paramValue;
              } else if (n.paramKey === 'singlePrintOrder') {
                if (n.paramValue !== null && n.paramValue !== undefined) {
                  v.pickingParams.singlePrintOrder = n.paramValue;
                }
              } else if (n.paramKey === 'multiPrintOrder') {
                if (n.paramValue !== null && n.paramValue !== undefined) {
                  v.pickingParams.multiPrintOrder = n.paramValue;
                }
              }
            });
          }
        }
      });
    }
  },
  computed: {
    isShowsTampPackage() {
      return this.$store.state.erpConfig.isSupplierSupportDirectDelivery === 1;
    }
  }
};
</script>

<style lang="less" scoped>
.details_box_styles {
  position: relative;

  .basic_information_box {
    /deep/ .ivu-form-item {
      margin-bottom: 8px;
    }

    .tooltip_boxs {
      cursor: pointer;
      margin-left: -230px;
    }

    .pick_box_setting {
      display: flex !important;
      flex-wrap: wrap;

      /deep/ .ivu-col {
        flex: 1;
      }

      .pickBox {
        //width: 93px;
        flex: 1;
        height: 50px;
        margin-top: 5px;
        margin-bottom: 5px;
        border: 1px solid #000;
        font-weight: bold;
        margin-right: 11px;
        box-sizing: border-box;
      }
    }
  }

  .options_content {
    box-shadow: 0px -3px 10px 0px #c9cbce;
    position: fixed;
    min-width: 100%;
    z-index: 10;
    background-color: #fff;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 8px 15px;
  }
}
</style>
